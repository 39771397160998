import React from 'react';
import {
    Divider,
    Grid,
    InputAdornment,
    Typography,
    TextField,
    Box,
    
} from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import useStatesFilter      from '@frontend/hooks/useStatesFilter';
import { usePhrases }       from '@frontend/utils/usePhrases';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';

import { 
    makeStyles
  } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textCenter: {
        textAlign: 'center',
        margin: 'auto',
    },
    heading: {
        textAlign: 'center',
        margin: 'auto',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    headingBox: {
        textAlign: 'left',
        margin: 'auto',
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
        fontSize: '0.7em',
        color: theme.palette.primary.main,
    },
    boxText: {
        textAlign: 'center',
        margin: 'auto',
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    
}));
const ReviewFlowSummary = (props) => {
    const { 
        qualityReports,
        activation,
        scrapPresent,
    } = props;
    const classes = useStyles();
    const { scrapTypeText } = useStatesFilter();
    const phrases = usePhrases().phrases();
    const { getLocale, formatDate } = useDateTimeFormat();

    return (<>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="h5" className={classes.heading}>
                    {phrases.modules.orders.reviewFlow.summary}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h6" color="textSecondary" className={classes.textCenter}>
                    {phrases.modules.orders.reviewFlow.summaryText}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="button" display="block" color="textSecondary">
                    {phrases.modules.orders.reviewFlow.activation}
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>     
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-machine"
                            name="machine"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.order_export.machine}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {activation.machine?.businessId}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-user"
                            name="user"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.order_export.user}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {activation.user.email}
                            </Typography>
                        </Box>                        
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-handCount"
                            name="handCount"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.order_export.handCount}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {activation.handCount}
                            </Typography>
                        </Box>                         
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-startTime"
                            name="startTime"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.timespanSelection.from}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {formatDate(activation.time, true)}
                            </Typography>
                        </Box>                            
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-endTime"
                            name="endTime"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.timespanSelection.to}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {formatDate(activation.endTime, true)}
                            </Typography>
                        </Box>                            
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-job"
                            name="job"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.order_export.job}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {activation.job.businessJobId}
                            </Typography>
                        </Box> 
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-product"
                            name="product"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.order_export.product}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {activation.job.product.name}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {scrapPresent && (<>
            <Grid item xs={12}>
                <Typography variant="button" display="block" color="textSecondary">
                    {phrases.modules.orders.reviewFlow.qualityReports}
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>      
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-scrapType"
                            name="scrapType"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.quality.scrapType}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {scrapTypeText(qualityReports.identifier)}
                            </Typography>
                        </Box>  
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-scrapType-count"
                            name="scrapType-count"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.quality.count}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {qualityReports.value}
                            </Typography>
                        </Box>                                
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-scrapType-count"
                            name="scrapType-note"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.forms.reason.misc.note}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {qualityReports.note}
                            </Typography>
                        </Box>                          
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            component="section" 
                            sx={{ p: 1, border: '1px dashed grey' }}
                            id="summary-scrapType-goodCount"
                            name="scrapType-goodCount"                            
                        >
                            <Typography variant="body1" component="p" className={classes.headingBox}>
                                {phrases.modules.orders.sensorCount}
                                <Divider />
                            </Typography>
                            <Typography variant="body1" component="p" className={classes.boxText}>
                                {activation.partsProduced}
                            </Typography>
                        </Box>                                
                    </Grid>
                </Grid>                    
            </Grid>
            </>)}
            
        </Grid>

    </>);
}
 
export default ReviewFlowSummary;