import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon }from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';

import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

import CheckBoxOutlineBlankIcon     from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon                 from '@material-ui/icons/CheckBox';

import ConfirmationModal              from '@frontend/components/ConfirmationModal';
import StyledSelect                   from '@frontend/components/StyledSelect';
import useOrdersExportActivationModal from '@frontend/modules/orders/hooks/useOrderExportActivationModal';
import HandCountCalculator            from '@frontend/modules/terminal/components/HandCountCalculator';
import useDateTimeFormat              from '@frontend/utils/useDateTimeFormat';
import { usePhrases }                 from '@frontend/utils/usePhrases';
import NumpadModal                    from '../../../components/NumpadModal';
import useReviewFlowRecord            from '@frontend/modules/orders/hooks/useReviewFlowRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  calculatorBtn: {
    display: 'flex',
    paddingTop: "12px",
    paddingBottom: "12px",  
  },
  helperText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  label: {
    color: '#757575',
    cursor: 'pointer',
  },
  heading: {
      textAlign: 'center',
      margin: 'auto',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      color: theme.palette.primary.main,
  },
  setupLabel: {
    marginTop: theme.spacing(1),
  }, 
}));

const ReviewFlowRecord = (props) => {
    const {
        job,        
        setActivation,
        globalActivation,
        workers,
        selectedMachine,
        setGlobalErrors
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { getLocale } = useDateTimeFormat();
    const [handCountCalculatorOpen, setHandCountCalculatorOpen] = useState(false);
    const [handCountNumpadOpen, setHandCountNumpadOpen] = useState(false);
    const {
        handleChange,
        handleChangeStartTime,
        handleChangeEndTime,
        errors,
        activationValues,        
        jobInformation,
        handleChangeHandCount,
        confirmationModalOpen,
        handleCloseConfirmationModal,
        handleConfirmIntendedMachine,
    } = useReviewFlowRecord(globalActivation, job, workers, selectedMachine, setActivation);
    const handleCloseHandCountCalculator = () => {
      setHandCountCalculatorOpen(false);
    }

    const handleCloseHandCountNumpad = () => {
      setHandCountNumpadOpen(false);
    }    

    useEffect(() => {
      setGlobalErrors(errors);
    }, [errors])

    return (             
    <>               
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="h5" className={classes.heading}>
                    {phrases.modules.orders.reviewFlow.activation}
                    {": "}
                    {jobInformation.job?.businessJobId}
                </Typography>
            </Grid>
          </Grid>          
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                      autoOk
                      ampm={false}
                      disableFuture
                      openTo="hours"
                      inputVariant="outlined"
                      margin="dense"
                      format={phrases.timespanSelection.timeFormat}
                      fullWidth
                      minutesStep={1}
                      maxDate={activationValues.time}
                      InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <AccessTimeIcon />
                          </InputAdornment>
                      ),
                      }}
                      value={activationValues.time}
                      onChange={handleChangeStartTime}
                      label={phrases.timespanSelection.from}
                      error={errors.time}
                      helperText={errors.time}
                      required
                  />
                </Grid>
                {selectedMachine?.config?.handCountStartEnd === "true" ? 
                <>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.handCount}
                    helperText={errors.handCount}
                    id="handCount-field"
                    name="handCount"
                    label={phrases.modules.order_export.handCount}
                    variant="outlined"
                    type="number"
                    fullWidth
                    onClick={() => setHandCountNumpadOpen(true)}
                    onChange={handleChangeHandCount}
                    value={activationValues.handCount}
                    margin="dense"
                    required
                  />
                </Grid>
                <div className={classes.calculatorBtn}>
                <Grid item xs={12} sm={3}  >              
                  <Button
                    onClick={()=>{setHandCountCalculatorOpen(true)}}                
                    size="medium"
                    color="primary"
                    variant="contained"
                    className={classes.calculatorBtn}
                  >
                    <FontAwesomeIcon
                      icon={faCalculator}
                      size="lg"
                    />
                  </Button>
                </Grid>
                  </div>
                </>: 
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errors.handCount}
                    helperText={errors.handCount}
                    id="handCount-field"
                    name="handCount"
                    label={phrases.modules.order_export.handCount}
                    variant="outlined"
                    type="number"
                    fullWidth
                    onClick={() => setHandCountNumpadOpen(true)}
                    onChange={handleChange}
                    value={activationValues.handCount}
                    margin="dense"
                    required
                  />
                </Grid>}
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                      autoOk
                      ampm={false}
                      disableFuture
                      openTo="hours"
                      inputVariant="outlined"
                      margin="dense"
                      format={phrases.timespanSelection.timeFormat}
                      fullWidth
                      minutesStep={1}
                      maxDate={activationValues.endTime}
                      InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <AccessTimeIcon />
                          </InputAdornment>
                      ),
                      }}
                      value={activationValues.endTime}
                      onChange={handleChangeEndTime}
                      label={phrases.timespanSelection.to}
                      error={errors.endTime ?? errors.activationPresent}
                      helperText={errors.endTime ?? errors.activationPresent}
                      required
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.setupLabel}>
                  <FormControlLabel
                      label={phrases.modules.order_export.isSetup}
                      control={<Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ margin: "auto" }}
                          checked={activationValues.isSetup}
                          onChange={handleChange}
                          color="primary"
                          name="isSetup"
                      />}
                  />
                </Grid>                
              </Grid>
          </MuiPickersUtilsProvider>     
          {/* <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="job-field"
                name="job"
                label={phrases.modules.order_export.selectedJob}
                variant="outlined"
                type="text"
                fullWidth
                value={activationValues.job?.businessJobId}
                margin="dense"
                disabled={true}
                notched={_.isEmpty(activationValues.job) ? false : true}
                InputLabelProps={{
                  shrink: _.isEmpty(activationValues.job) ? false : true,
                }}
              />
            </Grid>   
            <Grid item xs={12} sm={6}>  
              <TextField
                helperText={errors.product}
                id="selectedProduct-field"
                name="product"
                label={phrases.modules.order_export.product}
                variant="outlined"
                type="text"
                fullWidth
                value={jobInformation.job?.product?.name}
                margin="dense"
                disabled
                notched={_.isEmpty(jobInformation.job) ? false : true}
                InputLabelProps={{
                  shrink: _.isEmpty(jobInformation.job) ? false : true,
                }}
              />
            </Grid> 
          </Grid>
           */}
          <NumpadModal
            isOpen={handCountNumpadOpen}
            handleClose={handleCloseHandCountNumpad}
            handleCallback={handleChangeHandCount}
            modalTitle={phrases.modules.order_export.handCount}
          />

          <HandCountCalculator
            openModal={handCountCalculatorOpen}
            handleClose={handleCloseHandCountCalculator}
            setHandCount={handleChangeHandCount}
          />
          <ConfirmationModal
            title={phrases.modules.order_export.intendedMachineNotEqualtoMachineTitle}
            text={phrases.modules.order_export.intendedMachineNotEqualtoMachine}
            openModal={confirmationModalOpen}
            handleClose={handleCloseConfirmationModal}
            callback={handleConfirmIntendedMachine}
          />
        </>
        
    );
}
 
export default ReviewFlowRecord;