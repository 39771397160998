/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Drawer,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIndustry,
  faHome,
  faMicrochip,
  faUsers,
  faSignOutAlt,
  faCog,
  faColumns,
  faFileExport,
  faExclamationTriangle,
  faBusinessTime,
  faCalendarAlt,
  faEye,
  faStar,
  faTrashAlt,
  faReceipt,
  faTabletAlt,
  faQuestionCircle,
  faEnvelopeOpenText,
  faGlobe,
  faAlignCenter,
  faCodeBranch,
  faSearchPlus
} from '@fortawesome/free-solid-svg-icons';
import LinkIcon             from '@material-ui/icons/Link';
import LinkOff          from '@material-ui/icons/LinkOff';
import Slidedown from '@frontend/components/Slidedown';
import { ROUTES } from '@frontend/constants';
import loader from '@frontend/loader/loader';
import { removeUser } from '@frontend/modules/user/actions';
import useSecurity from '@frontend/utils/useSecurity';
import { closeDrawer, toggleDrawer } from '@frontend/utils/UIActions';
import { usePhrases } from '@frontend/utils/usePhrases';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import NavigationDrawerItem from '@frontend/components/NavigationDrawerItem';
import useOnlineIndicator   from '@frontend/modules/terminal/hooks/useOnlineIndicator';
import { FAV_IDS } from '../constants';

const drawerWidth = 250;
const closedDrawerWidth = 56;

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: drawerWidth,
    flexShrink: 0,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
    },
  },
  drawerOpen: {
    zIndex: theme.zIndex.appBar - 1,
    marginTop: theme.spacing(8),
    boxShadow: '-5px 8px 19px 0px rgba(0,0,0,0.22)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7),
      overflowY: 'visible',
    },
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 150,
    }),
  },
  drawerClose: {
    zIndex: theme.zIndex.appBar - 1,
    marginTop: theme.spacing(8),
    boxShadow: '-5px 1px 10px 0px rgba(0,0,0,0.05)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7),
      width: 0,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 150,
    }),
    overflow: 'visible',
    width: closedDrawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0.5),
    ...theme.mixins.toolbar,
  },
  collapseDrawerBtn: {
    borderRadius: '10%',
    padding: theme.spacing(1),
  },
  activeListItem: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  blackened: {
    width: 'calc(100% - 240px)',
    zIndex: theme.zIndex.appBar - 1,
    background: 'rgba(0,0,0,0.3)',
  },
  bottomList: {
    position: 'absolute',
    bottom: '60px',
  },
  green: {
    color: '#3bb273',
  },
  red: {
      color: '#d62828CC',
  },
  yellow: {
      color: '#ffff00'
  },
  grey: {
      color: '#828181'
  },
  onlineIndicator: {
    cursor: 'default',
  },
}));

const NavigationDrawer = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const dispatch = useDispatch();

  const [favListItems, setFavListItems]         = useState([]); 
  const [openListDropdown, setOpenListDropdown] = useState({});
  const [sideMenuOpen, setSideMenuOpen]         = useState({});
  
  const open              = useSelector((state) => state.drawerOpen);
  const userFavs          = useSelector((state) => state.userFavs);
  const loggedInUser      = useSelector((state) => state.user); 
  const selectedStructure = useSelector((state) => state.structure);
  
  const { moduleLoaded } = loader();
  const { conditionalElement } = useConditionalArrayElement();
  const { location } = props;
  const { 
    logout, 
    getProfile, 
    canAccessResource, 
    isSystemAdmin 
  } = useSecurity();
  const {
    onlineStatus,
    getTooltipText
  } = useOnlineIndicator();

  const handleSideMenu = (event) => {
    const target = event.currentTarget.id;
    const temp = {};
    if (!open) {
      temp[target] = !sideMenuOpen[target];
      setSideMenuOpen(temp);
    }
  };

  useEffect(() => { 
    // Filter the favs to only show the listEntries that are marked as favs
    const favListItems = listEntries.filter((entry) => userFavs.includes(entry.favId));
    setFavListItems(favListItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFavs])

  useEffect(() => {
    if (!open) setOpenListDropdown({});
  }, [open]);

  const handleCloseSideMenu = () => {
    setSideMenuOpen({});
  };

  const handleToggleDrawer = (e) => {
    e.stopPropagation();
    setOpenListDropdown({});
    dispatch(toggleDrawer());
  };

  const hadleCloseDrawer = (e) => {
    e.stopPropagation();
    setOpenListDropdown({});
    dispatch(closeDrawer());
  };

  const handleLogout = () => {
    logout();
    dispatch(removeUser());
  };
  const toggleAndCloseDropdown = (e) => {
    toggleSidebarDropdown(e);
    handleCloseSideMenu();
  };

  const toggleSidebarDropdown = (e) => {
    const target = e.currentTarget.id;
    const temp = {};
    if (open) {
      temp[target] = !openListDropdown[target];
      setOpenListDropdown(temp);
    } 
  };

  if (location.pathname.match(/terminal-overview/)) {
    return null;
  }

  const listEntries = [
    { 
      text: phrases.nav.oee, to: ROUTES.STRUCTURE_OVERVIEW, icon: faEye, object: 'structure', favId: FAV_IDS.OEE 
    },
    ...conditionalElement(moduleLoaded('reporting'), {
       text: phrases.nav.report, to: ROUTES.REPORTING_VIEW, id: selectedStructure ? selectedStructure.id : '', icon: faFileExport, object: 'structureID', favId: FAV_IDS.REPORT
    }),

    ...conditionalElement(isSystemAdmin(loggedInUser), { 
      text: phrases.nav.advanced_report, to: ROUTES.ADVANCED_REPORTING_VIEW, icon: faEye, object: 'structure', favId: FAV_IDS.ADVANCED_REPORT 
    }),

    ...conditionalElement(moduleLoaded('schedule'), { 
      text: phrases.nav.schedule, to: ROUTES.SCHEDULES, icon: faCalendarAlt, object: 'structure', favId: FAV_IDS.SCHEDULES
    }),
    
    ...conditionalElement(moduleLoaded('andon-board'), { 
      text: phrases.nav.andon, to: ROUTES.ANDON_BOARD, icon: faColumns, object: 'structure', favId: FAV_IDS.ANDON
    }),

    ...conditionalElement(moduleLoaded('orders'), { 
      text: phrases.nav.orders, to: ROUTES.ORDERS, icon: faReceipt, object: 'structure', favId: FAV_IDS.ORDERS 
    }),

    ...conditionalElement(moduleLoaded('orders'), { 
      text: phrases.nav.orders_export_validation, to: ROUTES.ORDERS_EXPORT_VALIDATION, icon: faSearchPlus, object: 'structure', favId: FAV_IDS.REVIEW 
    }),

    ...conditionalElement(moduleLoaded('terminal'), { 
      text: 'Terminal', to: ROUTES.APP_TERMINAL_OVERVIEW, icon: faTabletAlt, object: 'machine', favId: FAV_IDS.TERMINAL
    }),
  ];

  return (

    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <List disablePadding>
        <ListItem button onClick={handleToggleDrawer} className={classes.toolbar}>
          <IconButton onClick={handleToggleDrawer}>
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Typography noWrap color="textSecondary">
            {phrases.nav.collapseSidebar}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to={`${getProfile().views.homeView}`}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faHome}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary={phrases.nav.home} />
        </ListItem>
        {}
        <NavigationDrawerItem
          id="fav-dropdown"
          name={phrases.nav.views}
          icon={faStar}
          data={favListItems}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleSidebarDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
        />

        <NavigationDrawerItem
          id="views-dropdown"
          name={phrases.nav.views}
          icon={faEye}
          data={listEntries}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleAndCloseDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
          enableFavs={true}
        />
        <NavigationDrawerItem
          id="management-dropdown"
          name={phrases.nav.management}
          icon={faCog}
          data={[
            {
              text: `${phrases.nav.manageStructures} - ${phrases.nav.manageMachines}`, to: ROUTES.STRUCTURES_MANAGEMENT, icon: faIndustry,
            },
            ...conditionalElement(canAccessResource('reasons') || canAccessResource('machine_statuses'),
              {
                text: `${phrases.nav.machineStatusesManagement}${moduleLoaded('reasons') ? ` - ${phrases.nav.reasonsManagement}` : ''}`, to: moduleLoaded('reasons') ? ROUTES.REASONS_MANAGEMENT : ROUTES.MACHINE_STATUSES_MANAGEMENT, icon: faExclamationTriangle,
              }),
            ...conditionalElement(moduleLoaded('schedule') && canAccessResource('schedules'), {
              text: phrases.modules.schedule.schedules, to: ROUTES.SCHEDULE_MANAGEMENT, icon: faCalendarAlt,
            }),
            ...conditionalElement(moduleLoaded('machine_shift') && canAccessResource('machine_shift'), {
              text: phrases.modules.machine_shift.shifts, to: ROUTES.MACHINE_SHIFT_MANAGEMENT, icon: faBusinessTime,
            }),
            ...conditionalElement(moduleLoaded('quality') && canAccessResource('scrap-types'), {
              text: phrases.modules.quality.scrapTypes, to: ROUTES.SCRAP_TYPES_MANAGEMENT, icon: faTrashAlt,
            }),
            ...conditionalElement(moduleLoaded('orders') && canAccessResource('orders'), {
              text: phrases.nav.jobs, to: ROUTES.JOB_MANAGEMENT, icon: faReceipt,
            }),
            ...conditionalElement(canAccessResource('sensors') || canAccessResource('sensor-types'), {
              text: phrases.nav.sensors, to: ROUTES.SENSORS_MANAGEMENT, icon: faMicrochip,
            }),
            ...conditionalElement(canAccessResource('users') || canAccessResource('roles'), {
              text: phrases.nav.users, to: ROUTES.USERS_MANAGEMENT, icon: faUsers,
            }),
            ...conditionalElement(moduleLoaded('info'), { text: 'System Info', to: ROUTES.INFO, icon: faCodeBranch, object: 'machine' }),
            
          ]}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleSidebarDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
        />

        <NavigationDrawerItem
          id="help-dropdown"
          name={phrases.nav.help}
          icon={faQuestionCircle}
          data={[
            {
              text: phrases.nav.supportTicket, to: 'mailto:support@leanfocus.de?subject=LEANFOCUS%20Support%20Ticket', icon: faEnvelopeOpenText,
            },
            {
              text: phrases.nav.supportPage, to: 'https://support.leanfocus.de/help', icon: faGlobe, object: "http"
            },
          ]}
          openListDropdown={openListDropdown}
          handleSideMenu={handleSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
          toggleSidebarDropdown={toggleSidebarDropdown}
          sideMenuOpen={sideMenuOpen}
          hadleCloseDrawer={hadleCloseDrawer}
        />

      </List>
      <Divider />
      <List disablePadding>
        <ListItem
          button
          id="logout"
          onClick={handleLogout}
          onMouseEnter={handleSideMenu}
          onMouseLeave={handleCloseSideMenu}
        >
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary={phrases.nav.logout} />
          <Slidedown
            data={[
              { text: phrases.nav.logout, to: '', icon: faSignOutAlt },
            ]}
            close={handleCloseSideMenu}
            open={sideMenuOpen.logout}
          />
        </ListItem>
      </List>

      <List disablePadding className={classes.bottomList}>
        <Tooltip
          enterTouchDelay={50}
          leaveTouchDelay={3000}
          title={getTooltipText()}
          placement="top-start"
          arrow
        >
          <ListItem
            button
            id="online-indicator"
          >
            <ListItemIcon>
            
                {onlineStatus.health >= 2 ?
                  <LinkIcon 
                    fontSize="medium"
                    className={
                        clsx({
                            [classes.green]: onlineStatus.health >= 4,
                            [classes.yellow]: onlineStatus.health >= 2 && onlineStatus.health < 4,
                    })
                    } 
                  />: <LinkOff 
                    fontSize="medium"
                    className={classes.red}
                  />}
            </ListItemIcon>
            <ListItemText primary={phrases.modules.terminal.onlineStatus} />
          </ListItem>
        </Tooltip>
      </List>
    </Drawer>
  );
};

export default withRouter(NavigationDrawer);
