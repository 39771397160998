import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  
  Button,
  CircularProgress,
  Step,
  Stepper,
  StepLabel,
  Typography
} from '@material-ui/core';
import { usePhrases }             from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  finishButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  backToStartButton: {
    float: "right",
  },
}));

function StepperProgress(props) {
  const { 
    activeStepIndex,
    steps,
    handleNext,
    handleBack,
    handleReset,
    handleFinish,
    finishLoading,
    handleBackToStart,
  } = props;
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  return (<>
      <Stepper activeStep={activeStepIndex} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>      
      <div>
        {activeStepIndex === steps.length ? (
          <div>
            <Typography className={classes.instructions}>{phrases.forms.shared.fields.allStepsCompleted}</Typography>
            <Button variant='contained' color='secondary' onClick={handleReset}>{phrases.forms.shared.fields.reset}</Button>
          </div>
        ) : (
          <div>   
              <Button
                disabled={activeStepIndex === 0}
                onClick={handleBack}
                className={classes.backButton}
                variant='outlined'
              >
                {phrases.forms.shared.fields.back}
              </Button>
              {activeStepIndex === steps.length - 1 ? (
                <Button 
                  variant="contained" 
                  className={classes.finishButton} 
                  onClick={handleFinish ?? handleNext}
                  disabled={finishLoading}
                >
                  {phrases.forms.shared.fields.finish}
                  {finishLoading && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
                </Button>) : (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {phrases.forms.shared.fields.next}
                </Button>
              )}
              {handleBackToStart && (
                <Button
                  variant='outlined'
                  onClick={handleBackToStart}
                  className={classes.backToStartButton}
                >
                  {phrases.forms.shared.fields.backToStart}
                </Button>
              )}
          </div>
        )}
      </div>
      </>
  );
}

StepperProgress.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
};

export default StepperProgress;