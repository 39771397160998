import React, { useEffect } from 'react';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import LoadingIndicator                 from '@frontend/components/LoadingIndicator';
import useStatesFilter                  from '@frontend/hooks/useStatesFilter';
import useOrderExportQualityReportModal from '@frontend/modules/orders/hooks/useOrderExportQualityReportModal';
import useEditQualityReportForm         from '@frontend/modules/quality/hooks/useEditQualityReportForm';
import MuiNumpad                        from '@frontend/modules/quality/components/MUI-Numpad';
import useDateTimeFormat                from '@frontend/utils/useDateTimeFormat';
import { usePhrases }                   from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      width: '100%',
    },
    formBtn: {
      marginRight: theme.spacing(1),
      alignSelf: 'flex-end',
    },
    formButtons: {
      flexGrow: 1,
      display: 'flex',
      marginTop: theme.spacing(3),
    },
    loadingBtnIcon: {
      marginLeft: theme.spacing(1),
    },
    inputField: {
      width: '100%',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    reasonTitle: {
      marginBottom: theme.spacing(1),
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      margin: 'auto',
    },
    numpadWrapper: {
      margin: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    heading: {
        textAlign: 'center',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
  }));
  
const ReviewFlowQualityReport = (props) => {
    const {
        machine,
        activation,
        setQRData,
        qrData,
    } = props
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    const {
        qualityReports,
        selectedReport,
        selectedState,
        setSelectedReport
    } = useOrderExportQualityReportModal(activation);

    const {
        handleStartTimeChange,
        handleEndTimeChange,
        handleChangeScrapType,
        qualityReportValues,
        scrapTypesByCategory,
        handleChangeValue,
        handleChangeNote,
        isLoadingNote,
        loadingScrapTypes,
        errors,
    } = useEditQualityReportForm(null, selectedReport, true, qualityReports, machine, selectedState, activation?.partsProduced);
    const handleScrapTypeClick = (_scrapType) => {
        handleChangeScrapType(_scrapType);
    };

    useEffect(() => {
        setQRData(qualityReportValues)
    }, [qualityReportValues]);

    useEffect(() => {
      if(!_.isEmpty(qrData)) {
        setSelectedReport(qrData);
      }
    }, [qrData]);

    const handleFocusField = (event) => event.target.select();

    const { scrapTypeText } = useStatesFilter();
    const { getLocale } = useDateTimeFormat();

    return (<>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                  <Typography variant="h5" component="h5" className={classes.heading}>
                      {phrases.modules.orders.reviewFlow.qualityReports}
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5} style={{ position: 'relative' }}>
                    <div className={classes.numpadWrapper}>
                      <MuiNumpad
                        onChange={handleChangeValue}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">{phrases.modules.quality.scrapType}</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={qualityReportValues.identifier}
                        label={phrases.modules.quality.scrapType}
                        error={errors.identifier}
                      >
                        {scrapTypesByCategory.map((scrapType) =>
                          (<MenuItem id={scrapType.id} onClick={() => handleScrapTypeClick(scrapType)} data-scrap-type={scrapType} value={scrapType.identifier}>
                            {scrapTypeText(scrapType.identifier)}
                          </MenuItem>))}
                      </Select>
                      <FormHelperText error>{errors.identifier}</FormHelperText>
                    </FormControl>
                    <TextField
                      error={errors.value}
                      helperText={errors.value}
                      id="value"
                      name="value"
                      label={phrases.modules.quality.count}
                      variant="outlined"
                      fullWidth
                      onFocus={handleFocusField}
                      type="number"
                      className={classes.inputField}
                      onChange={(e) => handleChangeValue(e.target.value)}
                      value={qualityReportValues.value}
                      margin="dense"
                    />
                    <TextField
                      id="note"
                      name="note"
                      label={phrases.forms.reason.misc.note}
                      multiline
                      rows={3}
                      variant="outlined"
                      fullWidth
                      className={classes.inputField}
                      onChange={handleChangeNote}
                      value={qualityReportValues.note}
                      margin="dense"
                      disabled={isLoadingNote}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            {isLoadingNote && <CircularProgress color="inherit" size={15} />}
                          </InputAdornment>,
                      }}
                    />
                    
                    <Backdrop classes={{ root: classes.backdrop }} open={loadingScrapTypes}>
                      :
                      {' '}
                      <LoadingIndicator light />
                    </Backdrop>
                  </Grid>
                </Grid>
              </Grid>              
          </Grid>
    </>);
}
 
export default ReviewFlowQualityReport;